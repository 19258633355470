<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0734 17.8484V9.64844H17.8484V17.8484H13.0734ZM6.62344 17.8484V0.148438H11.3734V17.8484H6.62344ZM0.148438 17.8484V5.14844H4.92344V17.8484H0.148438Z"
      fill="currentColor"
    />
  </svg>
</template>
