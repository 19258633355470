<template>
  <svg
    width="12"
    height="20"
    viewBox="0 0 12 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.87344 19.375V17.15C3.85677 16.9167 3.01094 16.4708 2.33594 15.8125C1.66094 15.1542 1.18177 14.2417 0.898438 13.075L3.47344 12.1C3.70677 12.9667 4.05677 13.5917 4.52344 13.975C4.9901 14.3583 5.57344 14.55 6.27344 14.55C6.90677 14.55 7.40677 14.4333 7.77344 14.2C8.1401 13.9667 8.32344 13.6 8.32344 13.1C8.32344 12.6333 8.13177 12.2333 7.74844 11.9C7.3651 11.5667 6.52344 11.2083 5.22344 10.825C3.85677 10.4083 2.8901 9.85 2.32344 9.15C1.75677 8.45 1.47344 7.61667 1.47344 6.65C1.47344 5.63333 1.8026 4.78333 2.46094 4.1C3.11927 3.41667 3.92344 2.98333 4.87344 2.8V0.625H7.24844V2.8C8.14844 3 8.89427 3.37083 9.48594 3.9125C10.0776 4.45417 10.5151 5.13333 10.7984 5.95L8.27344 7C8.0901 6.5 7.81094 6.10833 7.43594 5.825C7.06094 5.54167 6.58177 5.4 5.99844 5.4C5.38177 5.4 4.93594 5.5125 4.66094 5.7375C4.38594 5.9625 4.24844 6.24167 4.24844 6.575C4.24844 7.00833 4.43594 7.35833 4.81094 7.625C5.18594 7.89167 6.02344 8.21667 7.32344 8.6C8.5401 8.96667 9.47344 9.53333 10.1234 10.3C10.7734 11.0667 11.0984 11.9833 11.0984 13.05C11.0984 14.3 10.7318 15.2583 9.99844 15.925C9.2651 16.5917 8.34844 17.0167 7.24844 17.2V19.375H4.87344Z"
      fill="currentColor"
    />
  </svg>
</template>
