<template>
  <div
    class="relative overflow-x-hidden bg-gradient-to-b from-ocean to-main-bg pb-[64px] pt-16 lg:pb-0 lg:pl-[64px] lg:pt-32"
  >
    <header class="fixed top-0 z-50 w-full lg:w-[calc(100%-64px)]">
      <AppHeader />
    </header>
    <main>
      <slot />
    </main>

    <div class="fixed bottom-0 z-50 w-full lg:hidden">
      <ProfileNav class="h-[64px]" orientation="horizontal" />
    </div>
    <div class="fixed left-0 top-0 z-50 hidden lg:block">
      <ProfileNav class="h-dvh w-[64px]" orientation="vertical" />
    </div>
  </div>
</template>
