<template>
  <nav
    :class="[
      'flex items-center border-navy bg-white',
      {
        'border-t': orientation === 'horizontal',
        'flex-col items-center justify-center divide-y divide-dashed divide-navy border-r py-5':
          orientation === 'vertical',
      },
    ]"
  >
    <div
      :class="[
        'flex items-center justify-center',
        {
          'py-5': orientation === 'vertical',
          'flex-1': orientation === 'horizontal',
        },
      ]"
    >
      <ProfileNavItem
        class="w-full"
        label="Stats"
        :label-hidden="orientation === 'vertical'"
        :active="nav.active === 'stats'"
        @click="onClick('stats')"
      >
        <IconsGraph class="h-4 w-4" />
      </ProfileNavItem>
    </div>

    <div
      :class="[
        'flex items-center justify-center',
        {
          'py-5': orientation === 'vertical',
          'flex-1': orientation === 'horizontal',
        },
      ]"
    >
      <ProfileNavItem
        class="w-full"
        label="Donations"
        :label-hidden="orientation === 'vertical'"
        :active="nav.active === 'donations'"
        @click="onClick('donations')"
      >
        <IconsEarthSearch class="h-5 w-5" />
      </ProfileNavItem>
    </div>

    <div
      :class="[
        'flex items-center justify-center',
        {
          'py-5': orientation === 'vertical',
          'flex-1': orientation === 'horizontal',
        },
      ]"
    >
      <ProfileNavItem
        class="w-full"
        label="Subscriptions"
        :label-hidden="orientation === 'vertical'"
        :active="nav.active === 'subscriptions'"
        @click="onClick('subscriptions')"
      >
        <IconsDollar class="h-5 w-5" />
      </ProfileNavItem>
    </div>
  </nav>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    orientation?: "horizontal" | "vertical";
  }>(),
  {
    orientation: "horizontal",
  },
);
const nav = useProfileNav();
function onClick(id: Section) {
  document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
}
</script>
